import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { Helmet } from 'react-helmet';

import withRouteParams from 'react/hoc/withRouteParams';
import SettingsPage from 'react/pages/settings/SettingsPageContainer';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import messages from './settings.messages';

const mapRouteParamsToProps = ({ view }) => ({
  view,
});

const enhancer = compose(
  withRouteParams(mapRouteParamsToProps),
);

const Settings = ({ view }) => (
  <ApplicationLayout>
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>

    <SettingsPage view={view} />
  </ApplicationLayout>
);

Settings.displayName = 'Settings';

Settings.propTypes = {
  view: PropTypes.string,
};

Settings.defaultProps = {
  view: '',
};

export default enhancer(Settings);
