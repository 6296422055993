import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

import { SETTINGS_VIEW } from 'config/constants';

import MySpaceHeader from 'react/business/mySpace/MySpaceHeader';
import SettingsLanguagePage from './SettingsLanguagePage';
import SettingsNotificationsPage from './SettingsNotificationsPage';

const enhancer = compose(
  memo,
);

const displayCurrentView = (view) => {
  switch (view) {
    case SETTINGS_VIEW.NOTIFICATIONS:
      return <SettingsNotificationsPage />;
    case SETTINGS_VIEW.LANGUAGE:
    default:
      return <SettingsLanguagePage />;
  }
};

const SettingsPage = ({ view }) => (
  <>
    <MySpaceHeader />
    { displayCurrentView(view) }
  </>
);

SettingsPage.displayName = 'SettingsPage';
SettingsPage.propTypes = {
  view: PropTypes.string,
};
SettingsPage.defaultProps = {
  view: '',
};

export default enhancer(SettingsPage);
