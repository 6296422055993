import React from 'react';
import PropTypes from 'prop-types';
import { formShape } from 'rc-form';
import { compose } from 'recompose';
import { Form, Select, Switch } from 'antd';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import { preferencesShape } from 'shapes/preferences';
import { LANGUAGES } from 'config/languages';
import Button from 'react/generic/button/Button';

import GLOBAL_MESSAGES from 'config/global.messages';
import MESSAGES from './preferences.messages';

import classNames from './preferences-form.module.scss';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const enhancer = compose(
  Form.create(),
);

class PreferencesForm extends React.PureComponent {
  static propTypes = {
    /** From Form.create. */
    form: formShape.isRequired,
    onSubmit: PropTypes.func.isRequired,
    preferences: preferencesShape,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    preferences: {},
    loading: false,
  };

  /** Set initial values in the fields. */
  componentDidMount() {
    const { preferences, form } = this.props;
    form.setFieldsValue(preferences);
  }

  /**
   * If we have new form values.
   *
   * @param {object} prevProps - Prev props.
   */
  componentDidUpdate(prevProps) {
    const { preferences, form } = this.props;
    if (preferences !== prevProps.preferences) {
      form.setFieldsValue(preferences);
    }
  }

  /**
   * Handle form submission.
   *
   * @param {Event} e - Form submit event.
   */
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  /** @returns {object} JSX. */
  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className={classNames.container}>
        <Form onSubmit={this.handleSubmit}>
          <div className={classNames.languageContainer}>
            <div className={classNames.languageLabel}>
              <FormattedMessage {...MESSAGES.APP_LANGUAGE} />
            </div>
            <Form.Item
              {...formItemLayout}
            >
              {getFieldDecorator('language')(
                <Select disabled={loading}>
                  {Object.values(LANGUAGES)
                    .map(lg => (
                      <Option value={lg.key} key={lg.key}>
                        {lg.label}
                      </Option>
                    ))}
                </Select>,
              )}
            </Form.Item>
          </div>
          <div className={classNames.translateContentContainer}>
            <span className={classNames.switchLabel}>
              <FormattedHTMLMessage
                {...MESSAGES.ALWAYS_TRANSLATE_CONTENT}
              />
            </span>
            <Form.Item
              {...formItemLayout}
            >
              {getFieldDecorator('alwaysTranslateContent', { valuePropName: 'checked' })(
                <Switch disabled={loading} />,
              )}
            </Form.Item>
          </div>
          <div className={classNames.buttonContainer}>
            <Button
              type="submit"
              className={classNames.button}
              disabled={loading}
            >
              <FormattedMessage {...GLOBAL_MESSAGES.SUBMIT} />
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}

export default enhancer(PreferencesForm);
