import React from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { compose, withHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { Switch } from 'antd';

import { preferencesShape } from 'shapes/preferences';

import withUserPreferences from 'react/business/preferences/connect/withUserPreferences.connect';
import withSetUserPreferences from 'react/business/preferences/connect/withSetUserPreferences.connect';

import NotificationMailingParameter from 'react/business/notifications/settings/NotificationMailingParameter';

import messages from './settings.messages';
import classNames from './settings-notifications.module.scss';

const enhancer = compose(
  withUserPreferences,
  withSetUserPreferences,

  withHandlers({
    onSwitchNotificationsChange: ({ preferences, setPreferences }) => async () => {
      const newValue = !preferences.replyNotificationsAvailable;
      const newPreferences = {
        ...preferences,
        replyNotificationsAvailable: newValue,
      };
      await setPreferences(newPreferences);
    },
    onSwitchEmailNotificationsChange: ({ preferences, setPreferences }) => async () => {
      const newValue = !preferences.replyEmailNotificationsAvailable;
      const newPreferences = {
        ...preferences,
        replyEmailNotificationsAvailable: newValue,
      };
      await setPreferences(newPreferences);
    },
  }),
);

const SettingsNotificationsPage = (
  {
    preferences,
    onSwitchNotificationsChange,
    onSwitchEmailNotificationsChange,
  },
) => (
  <div className={classNames.container}>
    <div className={classNames.settingsMailing}>
      <NotificationMailingParameter preferences={preferences} />
    </div>
    <div className={classNames.settings}>
      <span className={classNames.switchLabel}>
        <FormattedMessage {...messages.SETTINGS_NOTIFICATIONS_SWITCH} />
      </span>
      <Switch
        checked={preferences.replyNotificationsAvailable}
        onChange={onSwitchNotificationsChange}
      />
    </div>

    <div className={classNames.settings}>
      <span className={classNames.switchLabel}>
        <FormattedMessage {...messages.SETTINGS_EMAIL_NOTIFICATIONS_SWITCH} />
      </span>
      <Switch
        checked={preferences.replyEmailNotificationsAvailable}
        onChange={onSwitchEmailNotificationsChange}
      />
    </div>
  </div>
);

SettingsNotificationsPage.propTypes = {
  preferences: preferencesShape.isRequired,
  onSwitchNotificationsChange: PropTypes.func,
  onSwitchEmailNotificationsChange: PropTypes.func,
};

SettingsNotificationsPage.defaultProps = {
  onSwitchNotificationsChange: noop,
  onSwitchEmailNotificationsChange: noop,
};

export default enhancer(SettingsNotificationsPage);
