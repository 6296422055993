import { defineMessages } from 'react-intl';

export default defineMessages({
  TEXT_ALERT: {
    id: 'notifications.settings.mailing.label', defaultMessage: 'I want to receive a summary of my notifications by email:',
  },
  NEVER: {
    id: 'notifications.settings.choice.never', defaultMessage: 'Never',
  },
  DAILY: {
    id: 'notifications.settings.choice.daily', defaultMessage: 'Daily',
  },
  WEEKLY: {
    id: 'notifications.settings.choice.weekly', defaultMessage: 'Weekly',
  },
  MONTHLY: {
    id: 'notifications.settings.choice.monthly', defaultMessage: 'Monthly',
  },
});
