import React from 'react';
import PropTypes from 'prop-types';
import { withHandlers, compose } from 'recompose';
import { withToastManager } from 'react-toast-notifications';
import { FormattedMessage } from 'react-intl';

import { ACTIONS } from 'redux/users/constants';
import { preferencesShape } from 'shapes/preferences';
import PreferencesForm from 'react/business/preferences/PreferencesForm';

import withUserPreferences from 'react/business/preferences/connect/withUserPreferences.connect';
import withSetUserPreferences from 'react/business/preferences/connect/withSetUserPreferences.connect';

import messages from './settings.messages';
import classNames from './settings-language.module.scss';

const enhancer = compose(
  withUserPreferences,
  withSetUserPreferences,
  withToastManager,
  withHandlers({
    onSubmit: ({ preferences, setPreferences, toastManager }) => async (preferencesToSet) => {
      const {
        preferences: newPreferences,
        type,
      } = await setPreferences({
        ...preferences,
        ...preferencesToSet,
      });

      // Handle Toast displaying in case of updating success/failure
      if (type === ACTIONS.SET_CURRENT_USER_PREFERENCES_SUCCESS) {
        toastManager.add(
          <FormattedMessage {...messages.TOAST_UPDATE_SUCCESS} />,
          {
            appearance: 'success',
            autoDismiss: true,
          },
        );
      } else {
        toastManager.add(
          <FormattedMessage {...messages.TOAST_UPDATE_FAILURE} />,
          {
            appearance: 'error',
            autoDismiss: true,
          },
        );
      }

      // Force window reload if the language has changed.
      if (preferences.language !== newPreferences?.language) {
        window.location.reload();
      }
    },
  }),
);

const SettingsLanguagePage = ({ loading, preferences, onSubmit }) => (
  <div className={classNames.container}>
    <PreferencesForm
      onSubmit={onSubmit}
      loading={loading}
      preferences={preferences}
    />
  </div>
);

SettingsLanguagePage.propTypes = {
  preferences: preferencesShape.isRequired,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

SettingsLanguagePage.defaultProps = {
  loading: false,
};

export default enhancer(SettingsLanguagePage);
