import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { compose, withHandlers } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { preferencesShape } from 'shapes/preferences';
import withSetUserPreferences from 'react/business/preferences/connect/withSetUserPreferences.connect';

import Radio from 'react/generic/radio/Radio';

import { NOTIFICATIONS_MAILING_FREQUENCY_OPTIONS } from 'config/constants';

import messages from './notification-mailing-parameter.messages';
import classNames from './notificationMailingParameter.module.scss';

const enhancer = compose(
  withSetUserPreferences,

  withHandlers({
    onChange: ({ preferences, setPreferences }) => async (e) => {
      const newPreferences = {
        ...preferences,
        notificationMailingFrequency: e.target.value,
      };
      await setPreferences(newPreferences);
    },
  }),

  memo,
);

const options = [
  {
    label: <FormattedMessage {...messages.NEVER} />,
    value: NOTIFICATIONS_MAILING_FREQUENCY_OPTIONS.NEVER,
  },
  {
    label: <FormattedMessage {...messages.DAILY} />,
    value: NOTIFICATIONS_MAILING_FREQUENCY_OPTIONS.DAILY,
  },
  {
    label: <FormattedMessage {...messages.WEEKLY} />,
    value: NOTIFICATIONS_MAILING_FREQUENCY_OPTIONS.WEEKLY,
  },
  {
    label: <FormattedMessage {...messages.MONTHLY} />,
    value: NOTIFICATIONS_MAILING_FREQUENCY_OPTIONS.MONTHLY,
  },
];

const NotificationMailingParameter = ({ preferences, onChange }) => (
  <div className={classNames.container}>
    <FormattedMessage {...messages.TEXT_ALERT} />
    <Radio
      options={options}
      value={preferences.notificationMailingFrequency}
      onChange={onChange}
    />
  </div>
);

const { bool, func } = PropTypes;

NotificationMailingParameter.displayName = 'NotificationMailingParameter';
NotificationMailingParameter.propTypes = {
  preferences: preferencesShape.isRequired,
  loading: bool,
  setPreferences: func.isRequired,
  onChange: func.isRequired,
};
NotificationMailingParameter.defaultProps = {
  loading: false,
};

export default enhancer(NotificationMailingParameter);
